import React from 'react';
import { Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';

const FicheSources = ( props)=>{

  const { fiche } = props;
  const { t, i18n } = useTranslation();

  const labelStyle = { fontWeight: 'bold' };

  return (
    <Grid className="fiche-record-grid">
      <Grid.Row columns='equal'>
        <Grid.Column width={3} style={labelStyle} floated='right' textAlign='right'>
          Source 1
        </Grid.Column>
        <Grid.Column width={9} floated='left' textAlign='left'>
          <a href="#"> Link to source 1 goes here </a>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={3} style={labelStyle} floated='right' textAlign='right'>
          Source 2
        </Grid.Column>
        <Grid.Column width={9} floated='left' textAlign='left'>
          <a href="#"> Link to source 2 goes here </a>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={3} style={labelStyle} floated='right' textAlign='right'>
          Source 3
        </Grid.Column>
        <Grid.Column width={9} floated='left' textAlign='left'>
          <a href="#"> Link to source 3 goes here </a>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default FicheSources;
