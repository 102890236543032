import React, { useState } from 'react';
import FicheModal from './FicheModal.js';
import { useTranslation } from 'react-i18next';

const FicheCard = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const fiche = props.hit;
  const idString = fiche.JN_id.toString().padStart(4, "0");
  const { t, i18n } = useTranslation();

  const cardStyle = {
    margin: '0 10px 10px 0',
    display: 'inline-block',
    verticalAlign: 'top',
    boxShadow: '0 0.125rem 0.25rem rgb(0, 0, 0, 0.36)',
    borderRadius: '0.8em',
    padding: '1em',
    width: '200px',
    height: '250px',
    overflowY: 'auto'
  };

  return (
    <div
      className="card"
      key={fiche.JN_id}
      style={cardStyle}
      onClick={() => {
        if (!modalOpen) {
          setModalOpen(true)
        }
      }}
    >

    <FicheModal
      fiche={fiche}
      modalOpen={modalOpen}
      onClose={() => setModalOpen(false)}
    />

      <h4>Fiche {idString}</h4>
      <br />
      <p className="tags">
        <b>{t('labels.year')}:</b> {fiche.annee}
        <br />
        <b>{t('labels.date')}:</b> {fiche.date}
        <br />
        <b>{t('labels.place')}:</b> {fiche.lieu_JN}
        <br />

        <br />
        <b>Type fondamental (général):</b> {fiche.type_fonda}
        <br />
        <b>Type fondamental (détaillé):</b> {fiche.type_fonda_det}
        <br />

        <br />
        <b>Type secondaire (général):</b> {fiche.type_sec}
        <br />
        <b>Type secondaire (détaillé):</b> {fiche.type_sec_det}
        <br />
      </p>
    </div>
  );
};

export default FicheCard;
