import React, { useEffect, useRef, useState, Fragment } from 'react';
import mapboxgl from 'mapbox-gl';
import subtypes from './Subtypes'
import FicheModal from './FicheModal';
import { moisEnum, typeGenEnum, lieuEnum } from './Lookups';
import { useTranslation } from 'react-i18next';
import proj4 from 'proj4';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const MapView = (props)=> {

  // Lambert 93 (EPSG:2154) definition
  // (proj4('EPSG:4326') (WGS84) is already predefined by default, no need to define it here)
  proj4.defs("lambert","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

  const filteredData = props.data;
  const { t, i18n } = useTranslation();
  const mapRef = useRef();
  const [map, setMap] = useState();
  const [selectedFiche, setSelectedFiche]= useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [popup, setPopup] = useState();

  const geoData = {
    "type": "FeatureCollection",
    "features": []
  };

  // takes arg of an array of [x,y] lambert 93 coordinates
  const convertCoordinates = (coordinates) => {
    if ( !coordinates.every(coord => parseInt(coord)) ) return '';

    const conversion = proj4('lambert','EPSG:4326',coordinates.map(coord => coord * 100));
    return conversion.map(coord => coord.toString());
  }

  const formatDataForMap = (data) => {
    data.forEach((el) => {
      geoData.features.push(
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": convertCoordinates([el.x_cheflieu, el.y_cheflieu])
          },
          "properties": {
            "id": el.JN_id.toString(),
            "annee": el.annee.toString(),
            "date": el.date,
            "lieu_JN": el.lieu_JN,
            "dep_nom": el.dep_nom,
            "com_nom": el.com_nom,
            "duree": el.duree,
            "intensite": el.intensite.toString()
          }
        }
      );
    });
  };

  useEffect(() => {
    formatDataForMap(filteredData);
    const bourgesCoords = [2.3282637, 47.0780167]
    const mapInstance = new mapboxgl.Map({
      container: mapRef.current,
      style: process.env.REACT_APP_MAPBOX_STYLE,
      center: bourgesCoords,
      zoom: 5
    });

    mapInstance.on('load', function(){
      mapInstance.addSource('SearchResults',{
        type:'geojson',
        data: geoData
      })
      setMap(mapInstance);
      addMarkers(mapInstance);
    });
  },[]);

  useEffect(() => {
    formatDataForMap(filteredData);
    if (map) {
      map.getSource('SearchResults').setData(geoData);
    }
  },[filteredData])

  return (
    <Fragment>
      <div onClick={handleInfo}
        ref={mapRef}>
      </div>

      <FicheModal
        fiche={selectedFiche}
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />

    </Fragment>
  )

  function handleInfo(e) {
    let id = e.target.id;
    if (id.indexOf("modalTrigger") > -1 ) {
      let eventId = parseInt(id.split('-')[1]);
      popup.remove();
      getEvent(eventId);
      setModalOpen(true);
    }
  }

  function addMarkers(mapInstance){
    let m = map ? map : mapInstance;
    const layerName = 'SearchResultsLayer';
    removeExistingLayer(m, layerName);
    m.addLayer({
      id: layerName,
      type: "circle",
      source: 'SearchResults',
      'paint': {
        'circle-radius': [
          'match',
          ['get', 'intensite'],
          '1', 4,
          '2', 6,
          '3', 8,
          4
        ],
        "circle-color": "#8a3122",
        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
      },
    })
    m.on('click', layerName, (e) => {
      let id = e.features[0].properties["id"];
      let year = e.features[0].properties["annee"];
      let date = e.features[0].properties["date"];
      let placeType = e.features[0].properties["lieu_JN"];
      let dep_nom = e.features[0].properties["dep_nom"];
      let com_nom = e.features[0].properties["com_nom"];
      let duree = e.features[0].properties["duree"];
      let intensity = e.features[0].properties["intensite"];
      let description =
        `<p style='zIndex:99'>
          <b>Fiche: </b>${id}<br/>
          <b>${t('labels.year')}: </b>${year}<br/>
          <b>${t('labels.date')}: </b>${date}<br/>
          <b>${t('labels.placeType')}: </b>${placeType}<br/>
          <b>${t('labels.deptName')}: </b> ${dep_nom}<br/>
          <b>${t('labels.muniName')}: </b> ${com_nom}<br/>
          <b>${t('labels.duration')}: </b> ${duree}<br/>
          <b>${t('labels.intensity')}: </b> ${intensity}<br/>
          <br/>
          <br/>
          <button id="modalTrigger-${e.features[0].properties["id"]}">${t('labels.moreInfo')}</button>
        </p>`

      let coordinates = e.features[0].geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      let pu = new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(m);

      setPopup(pu);
    });
    m.on('mouseenter', layerName, function() {
      m.getCanvas().style.cursor = 'pointer';
    });
    m.on('mouseleave', layerName, function() {
      m.getCanvas().style.cursor = '';
    });
  }

  function removeExistingLayer(m, layerName) {
    if (m && m.getLayer(layerName)) {
      m.removeLayer(layerName)
    }
  }

  function getEvent(id) {
    const fiche = filteredData.find(el => el.JN_id === id);
    setSelectedFiche(fiche);
    return fiche;
  }

}

export default MapView
