import React, { useCallback } from 'react';
import { RefinementList } from 'react-instantsearch-dom';
import _ from 'underscore';
import Facet from './Facet';
import SliderInput from './SliderInput';

const SliderListFacet = (props) => {
  /**
   * Removes the numeric items from the passed list of items.
   *
   * @type {function(*): *}
   */
  const filterNumeric = useCallback((items) => (
    _.filter(items, (item) => _.isNaN(parseInt(_.first(item.label), 10)))
  ), []);

  return (
    <Facet
      className='slider-refinements'
      name={props.name}
    >
      <SliderInput
        attribute={props.attribute}
      />
      <RefinementList
        attribute={props.attribute}
        transformItems={filterNumeric}
      />
    </Facet>
  );
};

export default SliderListFacet;
