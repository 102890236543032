import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { Button } from 'semantic-ui-react';

const ClearFiltersButton = (props) => (
  <Button
    basic={props.basic}
    color={props.color}
    content={props.content}
    icon='times'
    disabled={!props.items.length}
    onClick={() => props.refine(props.items)}
  />
);

export default connectCurrentRefinements(ClearFiltersButton);
