import React from 'react';
import { withTranslation } from 'react-i18next';
import { connectStateResults } from 'react-instantsearch-dom';

const SearchResultsCount = ({ searchResults, t }) => (
  <div
    className='search-results-count'
  >
    { searchResults && (<h3>{t('messages.foundResultsCount', { count: searchResults.nbHits })}</h3>) }
  </div>
);

export default withTranslation()(connectStateResults(SearchResultsCount));
