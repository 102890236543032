import React, { useCallback } from 'react';
import { connectHitsPerPage } from 'react-instantsearch-dom';
import { Dropdown } from 'semantic-ui-react';
import _ from 'underscore';

const PageDropdown = (props) => {
  const transformItem = useCallback((item) => ({
    key: item.value,
    value: item.value,
    text: item.label
  }), []);

  return (
    <Dropdown
      basic
      button
      onChange={(e, { value }) => props.refine(value)}
      options={_.map(props.items, transformItem)}
      value={props.currentRefinement}
    />
  );
};

export default connectHitsPerPage(PageDropdown);
