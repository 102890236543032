import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import fr from './fr.json';

const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  },
};

const i18n = i18next.createInstance();

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'fr',
    lng: window.location.href.includes("en") ? "en" : "fr",
    interpolation: {
      escapeValue: false
    },
    resources
  });

export default i18n;
