import React, { useEffect, useRef, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { useTranslation } from 'react-i18next';
import { Hits, InstantSearch } from 'react-instantsearch-dom';
import { Button, Dimmer, Grid } from 'semantic-ui-react';
import ClearFiltersButton from './ClearFiltersButton';
import CopyURLButton from './CopyURLButton';
import CurrentFacets from './CurrentFacets';
import FicheCard from './FicheCard.js'
import Facets from './Facets.js'
import MapResults from './MapResults';
import PageDropdown from './PageDropdown';
import Pages from './Pages';
import SearchResultsCount from './SearchResultsCount';
import qs from 'qs';
import { useNavigate, useLocation } from 'react-router-dom'

import 'instantsearch.css/themes/satellite.css';

const createURL = state => `?${qs.stringify(state)}`;

const searchStateToUrl = searchState =>
  searchState ? createURL(searchState) : '';

const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

const DEBOUNCE_TIME = 250;

const FacetSearch = () => {
  const [filters, setFilters] = useState(false);

  const { t } = useTranslation();

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const debouncedSetStateRef = useRef(null);

  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(updatedSearchState));
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  return (
    <Dimmer.Dimmable
      as='div'
      className='ais-InstantSearch'
    >
      <InstantSearch
        indexName={process.env.REACT_APP_ALGOLIA_INDEX}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <h1>Jean Nicolas</h1>
        <SearchResultsCount />
        <Button
          basic
          content={t('labels.filters')}
          icon='filter'
          onClick={() => setFilters(true)}
          style={{
            display: 'inline-block'
          }}
        />
        <ClearFiltersButton
          basic
          color='red'
          content={t('labels.clearFilters')}
        />
        <PageDropdown
          defaultRefinement={1000}
          items={[
            { value: 20, label: t('labels.resultsPerPage', { count: '20' }) },
            { value: 50, label: t('labels.resultsPerPage', { count: '50' }) },
            { value: 100, label: t('labels.resultsPerPage', { count: '100' }) },
            { value: 500, label: t('labels.resultsPerPage', { count: '500' }) },
            { value: 1000, label: t('labels.resultsPerPage', { count: '1000' }) },
            { value: 9000, label: t('labels.resultsPerPage', { count: '9000' }) }
          ]}
        />
        <CopyURLButton />
        <CurrentFacets />
        <Grid
          className='results-grid'
          columns={2}
        >
          <Grid.Column
            width={9}
          >
            <div
              className="results-pane"
            >
              <Hits
                hitComponent={FicheCard}
              />
            </div>
            <Pages
              onChange={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              size='small'
            />
          </Grid.Column>
          <Grid.Column
            width={7}
          >
            <MapResults />
          </Grid.Column>
        </Grid>
        <Dimmer
          className='facet-container'
          active={filters}
          style={{
            color: 'unset',
            overflow: 'auto',
            position: 'fixed',
            justifyContent: 'flex-start'
          }}
        >
          <Button
            content={t('labels.close')}
            onClick={() => setFilters(false)}
          />
          <Facets />
          <Button
            content={t('labels.close')}
            onClick={() => setFilters(false)}
          />
        </Dimmer>
      </InstantSearch>
    </Dimmer.Dimmable>
  );
};

export default FacetSearch;
