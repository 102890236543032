import React from 'react';
import { Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { lieuEnum } from './Lookups';
import Lookups from './lookups.json';
import proj4 from 'proj4';

const FicheRecord = ( props)=> {

  // Lambert 93 (EPSG:2154) definition
  // (proj4('EPSG:4326') (WGS84) is already predefined by default, no need to define it here)
  proj4.defs("lambert","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

  // takes arg of an array of [x,y] lambert 93 coordinates
  const convertCoordinates = (coordinates) => {
    if ( !coordinates.every(coord => parseInt(coord)) ) return '';

    const conversion = proj4('lambert','EPSG:4326',coordinates.map(coord => coord * 100));
    return conversion.map(coord => coord.toString());
  };

  const consolidateValues = (array) => {
    let results = [];
    let excludedTerms = [
      "Pas d'autre intervention",
      "Pas d'autre catégorie",
      "Pas d'autre forme",
      "Pas d'autre armes",
      "Pas d'autre insultes"
    ];
    array.forEach((val) => {
      if (!excludedTerms.includes(val)) {
        results.push(val);
      }
    })
    return [...new Set(results)].join(", ")
  };

  const { fiche } = props;
  const { t, i18n } = useTranslation();

  const labelStyle = { fontWeight: 'bold' };
  return (
    <Grid className="fiche-record-grid">
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jnId')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.JN_id}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jnIdProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.JN_id_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jnType')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.JN_type}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.typeFonda')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.type_fonda}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.typeFondaProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.type_fonda_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.typeFondaDet')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.type_fonda_det}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.typeFondaDetProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.type_fonda_det_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.typeSec')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.type_sec}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.typeSecProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.type_sec_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.typeSecDet')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.type_sec_det}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.typeSecDetProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.type_sec_det_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.annee')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.annee}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.anneeProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.annee_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.mois')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.mois}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.moisProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.mois_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jour')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.jour}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jourProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.jour_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.date')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.date}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jsemaine')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.jsemaine}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.duree')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.duree}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.lieuJn')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.lieu_JN}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.lieuType')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.lieu_type}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.pays')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.pays}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.dep')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.dep}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.depNom')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.dep_nom}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.com')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.com}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.artmaj')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.artmaj}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.comNom')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.com_nom}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.insee')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.insee}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.inseeProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.insee_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.xCheflieu')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.x_cheflieu}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.yCheflieu')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.y_cheflieu}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.frontiere')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.frontiere}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.protestant')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.protestant}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.participantsNombre')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.participants_nombre}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.participantsNombreQualif')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.participants_nombre_qualif}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.participantsNombreOriginal')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.participants_nombre_original}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.intensite')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.intensite}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.intensiteProv')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.intensite_prov}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.participantsSexe')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.participants_sexe}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.participantsAge')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.participants_age}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.participantsCatsocMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.participants_catsoc_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.participantsCatsocNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.participants_catsoc_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.participantsCatsoc')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {consolidateValues(fiche.participants_catsoc)}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.interventionMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.intervention_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.interventionsNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.interventions_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.intervention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {consolidateValues(fiche.intervention)}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.affrontementMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.affrontement_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.affrontementNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.affrontement_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.affrontementFormes')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {consolidateValues(fiche.affrontement_formes)}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.armesMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.armes_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.armesNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.armes_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.affrontementArmes')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {consolidateValues(fiche.affrontement_armes)}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.insultesMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.insultes_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.insultesNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.insultes_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.affrontementInsultes')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {consolidateValues(fiche.affrontement_insultes)}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutBlesse')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_blesse}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutBlesseNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_blesse_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutTue')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_tue}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutTueNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_tue_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutCapture')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_capture}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutCaptureNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_capture_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutLibere')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_libere}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutLibereNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_libere_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutCaplib')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_caplib}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutCaplibNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_caplib_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutArret')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_arret}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutArretNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_arret_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutEcroue')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_ecroue}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.emeutEcroueNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.emeut_ecroue_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advDecoiffe')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_decoiffe}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advDecoiffeNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_decoiffe_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advDechire')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_dechire}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advDechireNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_dechire_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advDesarme')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_desarme}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advDesarmeNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_desarme_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advSequestre')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_sequestre}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advSequestreNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_sequestre_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advFuite')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_fuite}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advFuiteNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_fuite_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advBlesse')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_blesse}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advBlesseNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_blesse_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advTue')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_tue}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advTueNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_tue_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advCadavre')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_cadavre}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advCadavreNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_cadavre_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advCoups')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_coups}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.advCoupsNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.adv_coups_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensDocBrul')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_doc_brul}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensDocVol')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_doc_vol}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensDocDech')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_doc_dech}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensPlacArach')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_plac_arach}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensImmeubLapid')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_immeub_lapid}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensImmeubEnvahi')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_immeub_envahi}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensImmeubPille')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_immeub_pille}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensImmeubDemoli')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_immeub_demoli}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensImmeubIncendie')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_immeub_incendie}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensBris')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_bris}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensBois')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_bois}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensRecolte')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_recolte}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensBetail')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_betail}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensMarchDet')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_march_det}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensMarchPille')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_march_pille}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensMarchDetr')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_march_detr}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensMarchBrul')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_march_brul}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.biensMarchTax')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.biens_march_tax}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.batMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.bat_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.batPub')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.bat_pub}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.batSeign')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.bat_seign}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.batEccles')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.bat_eccles}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.batDemOfficier')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.bat_dem_officier}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.batDemNotable')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.bat_dem_notable}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.batDivers')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.bat_divers}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jugMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.jug_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jugSimple')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.jug_simple}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jugBail')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.jug_bail}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jugParl')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.jug_parl}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.jugPrev')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.jug_prev}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineRoue')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_roue}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineRoueNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_roue_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peinePendaison')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_pendaison}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peinePendaisonNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_pendaison_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineGalerePerpet')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_galere_perpet}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineGalerePerpetNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_galere_perpet_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineGalereTemps')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_galere_temps}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineGalereTempsNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_galere_temps_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineMention')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_mention}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineBan')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_ban}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineBanNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_ban_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineCarcan')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_carcan}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineCarcanNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_carcan_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineAmende')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_amende}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineAmendeNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_amende_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineAdmo')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_admo}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineAdmoNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_admo_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineDeten')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_deten}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.peineDetenNb')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.peine_deten_nb}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.chercheurId')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.chercheur_id}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.chercheurInit')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.chercheur_init}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.chercheurNomComplet')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.chercheur_nom_complet}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.chercheurPrenom')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.chercheur_prenom}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns='equal'>
        <Grid.Column width={4} style={labelStyle} floated='right' textAlign='right'>
          {t('labels.chercheurNom')}
        </Grid.Column>
        <Grid.Column width={8} floated='left' textAlign='left'>
          {fiche.chercheur_nom}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default FicheRecord;
