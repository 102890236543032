import React, { useCallback } from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { Label } from 'semantic-ui-react';
import _ from 'underscore';

const CurrentFacets = (props) => {
  /**
   * Renders the label for the passed item.
   *
   * @type {(function(*, *): (*))|*}
   */
  const renderLabel = useCallback((item, parent) => {
    if (item.items) {
      return _.map(item.items, (child) => renderLabel(child, item));
    }

    return (
      <Label
        content={`${parent ? parent.label : ''}${item.label}`}
        onRemove={() => props.refine(item.value)}
      />
    );
  }, [props.refine]);

  return (
    <Label.Group
      className='current-facets'
    >
      { _.map(props.items, renderLabel)}
    </Label.Group>
  );
};

export default connectCurrentRefinements(CurrentFacets);
