import React, { useEffect } from "react";
import mirador from "mirador";
import fiches from '../fiches_map.json'

const Mirador = (props) => {
  const { idString } = props;

  const id = parseInt(idString, 10).toString();

  const iiif_uuid = fiches.find(f => f['fiche_id'] === id).uuid;

  const config = {
    "canvasNavigation": {
     "height": 50,
     "width": 50
   },
    "window": {
      "allowClose": true,
      "allowMaximize": true,
      "defaultView": "single"
    },
    "windows": [
      {
        "loadedManifest": `${process.env.REACT_APP_IIIF_CLOUD_URL}/public/resources/${iiif_uuid}/manifest`,
        "canvasIndex": 0
      },
    ],
    "workspaceControlPanel": {
      "enabled": false
    },
    "id": "mirador"
  };

  useEffect(() => {
    mirador.viewer(config);
  }, []);

  return <div id={config.id} style={{height: '600px'}} />;
}

export default Mirador;
