import React from 'react';
import { Segment } from 'semantic-ui-react';

const Facet = (props) => (
  <Segment
    className={props.className}
  >
    <h4>{ props.name }</h4>
    { props.children }
  </Segment>
);

export default Facet;
