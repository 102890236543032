import React from 'react';
import {
  Modal,
  Tab
} from 'semantic-ui-react';
import MiradorViewer from './MiradorViewer';
import FicheRecord from './FicheRecord';
import FicheSources from './FicheSources';
import { useTranslation } from 'react-i18next';

const FicheModal = (props) => {
  const { fiche, modalOpen, onClose } = props;
  const { t, i18n } = useTranslation();
  const idString = fiche && fiche.JN_id ? fiche.JN_id.toString().padStart(4, "0") : "";

  const handleClose = () => {
    onClose();
  };

  const recordTab = (fiche) => {
    return (
      <Tab.Pane attached={false}>
        <FicheRecord fiche={fiche} />
      </Tab.Pane>
    );
  };

  const imageTab = (fiche) => {
    return (
      <Tab.Pane>
        <MiradorViewer idString={idString} />
     </Tab.Pane>
    );
  };

  const linksTab = (fiche) => {
    return (
      <Tab.Pane attached={false}>
        <FicheSources fiche={fiche} />
      </Tab.Pane>
    );
  };

  return (
    <Modal
      fiche={fiche}
      open={modalOpen}
      closeIcon
      onClose={handleClose}
      size='small'
    >
      <Modal.Header>
        <h1>Fiche {idString}</h1>
      </Modal.Header>
      <Modal.Content >
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: t('labels.viewRecord'),
              render: () => recordTab(fiche)
            },
            {
              menuItem: t('labels.viewImages'),
              render: () => imageTab(fiche)
            },
            {
              menuItem: t('labels.viewSourceLinks'),
              render: () => linksTab(fiche)
            }
          ]}
        />
      </Modal.Content>
    </Modal>
  );
};

export default FicheModal;
