import React from 'react';
import FacetSearch from './components/FacetSearch'
import './App.css';


const App = () => {
  return (
    <FacetSearch />
  );
}

export default App;
