import React from 'react';
import { Button } from 'semantic-ui-react';

const CopyURLButton = () => (
  <Button
    basic
    content='Copier cette requète'
    onClick={() => navigator.clipboard.writeText(window.location)}
  />
)

export default CopyURLButton;
