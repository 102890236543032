import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import MapView from './Map';

const MapResults = ({ searchResults }) => {
  if (!searchResults) {
    return null;
  }

  return (
    <MapView
      data={searchResults.hits}
    />
  );
};

export default connectStateResults(MapResults);
