import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RangeInput, RefinementList } from 'react-instantsearch-dom';
import { Accordion, Icon, Segment } from 'semantic-ui-react';
import Facet from './Facet';
import SliderInput from './SliderInput';
import SliderListFacet from './SliderListFacet';

import 'instantsearch.css/themes/satellite.css';

const FACET_LIMIT = 8;
const FACET_LIMIT_SEARCH = 6;

const Facets = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  };

  return (
    <div
      className='facets'
    >
      <Accordion
        as={Segment}
        fluid
        styled
      >
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          <h3>{t('labels.time')}</h3>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Facet
            name={t('labels.jnId')}
          >
            <RangeInput
              attribute='JN_id'
              translations={{
                submit: t('labels.go'),
                separator: t('labels.to')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.annee')}
          >
            <RangeInput
              attribute='annee'
              translations={{
                submit: t('labels.go'),
                separator: t('labels.to')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.duree')}
          >
            <RefinementList
              attribute='duree'
              limit={100}
              showMore
            />
          </Facet>
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          <h3>{t('labels.space')}</h3>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <Facet
            name={t('labels.pays')}
          >
            <RefinementList
              attribute='pays'
              limit={FACET_LIMIT}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.muniName')}
          >
            <RefinementList
              attribute="com_nom"
              limit={FACET_LIMIT_SEARCH}
              searchable
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.deptName')}
          >
            <RefinementList
              attribute='dep_nom'
              limit={FACET_LIMIT_SEARCH}
              searchable
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.lieuJn')}
          >
            <RefinementList
              attribute='lieu_JN'
              limit={FACET_LIMIT_SEARCH}
              searchable
              showMore
              showMorLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.frontiere')}
          >
            <RefinementList
              attribute='frontiere'
              limit={100}
              showMore
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          <h3>{t('labels.people')}</h3>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <Facet
            name={t('labels.participantsCatsoc')}
          >
            <RefinementList
              attribute='participants_catsoc'
              limit={FACET_LIMIT_SEARCH}
              searchable
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
              transformItems={items =>
                items.filter(item => item.label !== "Pas d'autre catégorie")
              }
            />
          </Facet>
          <Facet
            name={t('labels.participantsSexe')}
          >
            <RefinementList
              attribute='participants_sexe'
              limit={100}
              showMore
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.affrontementArmes')}
          >
            <RefinementList
              attribute='affrontement_armes'
              limit={FACET_LIMIT}
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
              transformItems={items =>
                items.filter(item => item.label !== "Pas d'autre armes")
              }
            />
          </Facet>
          <Facet
            name={t('labels.affrontementFormes')}
          >
            <RefinementList
              attribute='affrontement_formes'
              limit={FACET_LIMIT}
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
              transformItems={items =>
                items.filter(item => item.label !== "Pas d'autre forme")
              }
            />
          </Facet>
          <Facet
            name={t('labels.affrontementInsultes')}
          >
            <RefinementList
              attribute='affrontement_insultes'
              limit={FACET_LIMIT}
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
              transformItems={(items) => (
                items.filter(item => item.label !== "Pas d'autre insultes" && item.label !== "Pas d'insultes")
              )}
            />
          </Facet>
          <SliderListFacet
            attribute='emeut_arret_nb'
            name={t('labels.emeutArretNb')}
          />
          <SliderListFacet
            attribute='emeut_blesse_nb'
            name={t('labels.emeutBlesseNb')}
          />
          <SliderListFacet
            attribute='emeut_caplib_nb'
            name={t('labels.emeutCaplibNb')}
          />
          <SliderListFacet
            attribute='emeut_capture_nb'
            name={t('labels.emeutCaptureNb')}
          />
          <SliderListFacet
            attribute='emeut_ecroue_nb'
            name={t('labels.emeutEcroueNb')}
          />
          <SliderListFacet
            attribute='emeut_libere_nb'
            name={t('labels.emeutLibereNb')}
          />
          <Facet
            name={t('labels.emeutMention')}
          >
            <RefinementList
              attribute='emeut_mention'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <SliderListFacet
            attribute='emeut_tue_nb'
            name={t('labels.emeutTueNb')}
          />
          <Facet
            name={t('labels.insultesNb')}
          >
            <SliderInput
              attribute='insultes_nb'
            />
          </Facet>
          <Facet
            name={t('labels.protestant')}
          >
            <RefinementList
              attribute='protestant'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 3}
          index={3}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          <h3>{t('labels.authorities')}</h3>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 3}>
          <Facet
            name={t('labels.jugBail')}
          >
            <RefinementList
              attribute='jug_bail'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.jugMention')}
          >
            <RefinementList
              attribute='jug_mention'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>

          <Facet
            name={t('labels.jugParl')}
          >
            <RefinementList
              attribute='jug_parl'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.jugPrev')}
          >
            <RefinementList
              attribute='jug_prev'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.jugSimple')}
          >
            <RefinementList
              attribute='jug_simple'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.peineAdmo')}
          >
            <RefinementList
              attribute='peine_admo'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <SliderListFacet
            attribute='peine_admo_nb'
            name={t('labels.peineAdmoNb')}
          />
          <SliderListFacet
            attribute='peine_amende_nb'
            name={t('labels.peineAmendeNb')}
          />
          <SliderListFacet
            attribute='peine_ban_nb'
            name={t('labels.peineBanNb')}
          />
          <SliderListFacet
            attribute='peine_carcan_nb'
            name={t('labels.peineCarcanNb')}
          />
          <SliderListFacet
            attribute='peine_deten_nb'
            name={t('labels.peineDetenNb')}
          />
          <SliderListFacet
            attribute='peine_galere_perpet_nb'
            name={t('labels.peineGalerePerpetNb')}
          />
          <SliderListFacet
            attribute='peine_galere_temps_nb'
            name={t('labels.peineGalereTempsNb')}
          />
          <Facet
            name={t('labels.peineMention')}
          >
            <RefinementList
              attribute='peine_mention'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <SliderListFacet
            attribute='peine_pendaison_nb'
            name={t('labels.peinePendaisonNb')}
          />
          <SliderListFacet
            attribute='peine_roue_nb'
            name={t('labels.peineRoueNb')}
          />
          <Facet
            name={t('labels.intervention')}
          >
            <RefinementList
              attribute='intervention'
              limit={FACET_LIMIT_SEARCH}
              searchable
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
              transformItems={items =>
                items.filter(item => item.label !== "Pas d'autre intervention")
              }
            />
          </Facet>
          <Facet
            name={t('labels.interventionsNb')}
          >
            <SliderInput
              attribute='interventions_nb'
            />
          </Facet>
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 4}
          index={4}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          <h3>{t('labels.type')}</h3>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 4}>
          <Facet
            name={t('labels.intensite')}
          >
            <RefinementList
              attribute="intensite"
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name='Type fondamental (général)'
          >
            <RefinementList
              attribute='type_fonda'
              limit={FACET_LIMIT_SEARCH}
              searchable
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name='Type fondamental (détaillé)'
          >
            <RefinementList
              attribute='type_fonda_det'
              limit={FACET_LIMIT_SEARCH}
              searchable
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name='Type secondaire (général)'
          >
            <RefinementList
              attribute='type_sec'
              limit={FACET_LIMIT_SEARCH}
              searchable
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name='Type secondaire (détaillé)'
          >
            <RefinementList
              attribute="type_sec_det"
              limit={FACET_LIMIT_SEARCH}
              searchable
              showMore
              showMoreLimit={Number.MAX_SAFE_INTEGER}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.typeSecDetProv')}
          >
            <RefinementList
              attribute='type_sec_det_prov'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
          <Facet
            name={t('labels.typeSecProv')}
          >
            <RefinementList
              attribute='type_sec_prov'
              limit={100}
              showMore={true}
              translations={{
                placeholder: t('labels.search'),
                showMore: (expanded) => expanded
                  ? t('labels.showLess')
                  : t('labels.showMore')
              }}
            />
          </Facet>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default Facets;
