import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import { Pagination } from 'semantic-ui-react';

const Pages = (props) => (
  <div
    className='pages'
  >
    <Pagination
      activePage={props.currentRefinement}
      onPageChange={(e, { activePage }) => {
        props.refine(activePage);
        props.onChange && props.onChange(activePage);
      }}
      size={props.size}
      totalPages={props.nbPages}
    />
  </div>
);

export default connectPagination(Pages);
